<template>
<div class="restaurants">
    <h1>Nos restaurants partenaires</h1>
    <div class="filters">
        <p-inputText v-model="search" placeholder="Rechercher un restaurant..." class="search-bar" />
        <p-dropdown v-model="selectedCuisine" :options="cuisineOptions" optionLabel="label" placeholder="Toutes les cuisines" class="filter-select"></p-dropdown>
        <p-dropdown v-model="selectedRating" :options="ratingOptions" optionLabel="label" placeholder="Toutes les notes" class="filter-select"></p-dropdown>
        <Button label="Réinitialiser les filtres" @click="resetFilters" class="reset-button" />
    </div>
    <div class="restaurant-list">
        <p-card v-for="restaurant in paginatedRestaurants" :key="restaurant.id" class="restaurant-card">
            <template #header>
                <img :src="restaurant.image" alt="Restaurant Image" class="restaurant-image">
            </template>
            <template #content>
                <h2>{{ restaurant.name }}</h2>
                <p>{{ restaurant.tag }}</p>
                <p>{{ restaurant.description }}</p>
                <div>Note: <strong class="rating">{{ restaurant.rating }}</strong></div>
                <p>Adresse: {{ restaurant.location }}</p>
            </template>
            <template #footer>
                <router-link :to="'/restaurant/' + restaurant.id" class="link">Voir le détails</router-link>
            </template>
        </p-card>
    </div>
    <div class="pagination">
        <Button label="Previous" @click="currentPage--" :disabled="currentPage === 1" class="reset-button" />
        <div>Page {{ currentPage }} of {{ Math.ceil(filteredRestaurants.length / itemsPerPage) }}</div>
        <Button label="Next" @click="currentPage++" :disabled="currentPage === Math.ceil(filteredRestaurants.length / itemsPerPage)" class="reset-button" />
    </div>
</div>
</template>

<script>
import {
    restaurants
} from '@/data/restaurants.js';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Card from 'primevue/card';

export default {
    data() {
        return {
            search: '',
            selectedCuisine: null,
            selectedRating: null,
            restaurants: restaurants,
            currentPage: 1,
            itemsPerPage: 4
        };
    },
    methods: {
        resetFilters() {
            this.selectedCuisine = null;
            this.selectedRating = null;
        },
    },
    computed: {
        filteredRestaurants() {
            return this.restaurants.filter(restaurant => {
                return (this.selectedCuisine ? restaurant.cuisine === this.selectedCuisine : true) &&
                    (this.selectedRating ? restaurant.rating === this.selectedRating : true);
            });
        },
        paginatedRestaurants() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.filteredRestaurants.slice(start, end);
        },
    },
    components: {
        'p-inputText': InputText,
        'Button': Button,
        'p-dropdown': Dropdown,
        'p-card': Card,
    },
}
</script>

<style scoped>
h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.restaurants {
    max-width: 800px;
    margin: 0 auto;
}

.filters {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.restaurant-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.restaurant-card {
    width: 45%;
    margin-bottom: 20px;
    animation: fadeIn 0.5s ease-in forwards;
    transition: transform 0.3s ease-in-out;
    padding: 2%;
    border: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.restaurant-card:hover {
    transform: scale(1.05);
}

.restaurant-image {
    width: 100%;
    height: auto;
    max-width: 500px;
    object-fit: cover;
    border-radius: 5px;
}

.filters .p-inputtext,
.filters .p-dropdown {
    flex: 1;
    margin-right: 10px;
}

.p-dropdown {
    margin-top: 10px;
}

.link {
    display: inline-block;
    padding: 5px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: rgb(255, 99, 71);
    color: white;
    text-decoration: none;
    border-radius: 5px;
}

.link:hover {
    background-color: #ff6347c5;
}

.rating {
    font-size: 1.1em;
    color: #ff6347;
}

.reset-button {
    background-color: #ff6347;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.reset-button:hover {
    background-color: #ff6347c5;
}

.search-bar {
    width: 100%;
    padding: 10px;
}

.settings {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.filters {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.filter-select {
    width: 100%;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .restaurant-card {
        width: 90%;
        margin: auto;
        margin-bottom: 5%;
    }

    .search-bar {
        width: auto;
        margin-bottom: 2%;
    }

    .settings {
        flex-direction: column;
    }

    .filters {
        flex-direction: column;
    }

    .filter-select {
        width: auto;
    }
}
</style>
