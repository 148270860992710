<template>
<HeaderComponent />
<RestaurantComponent />
<FooterComponent />
</template>

<script>
import RestaurantComponent from '@/components/RestaurantComponent.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
    methods: {},
    name: 'MainPageComponent',
    components: {
        RestaurantComponent,
        HeaderComponent,
        FooterComponent
    }
}
</script>

<style>
body {
    margin: 0;
    background-color: rgb(250, 235, 215);
}

#app {
    font-family: Cinzel;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
