<template>
<div class="header" :style="{ backgroundImage: `url(${headerImage})` }">
    <div class="overlay">
        <h1>{{ headerTitle }}</h1>
        <p>{{ headerText }}</p>
    </div>
</div>
</template>

  
  
<script>
import headerPicture from '@/assets/header-picture.jpeg';
export default {
    props: {
        headerTitle: {
            type: String,
            default: 'Pics\'Meals'
        },
        headerText: {
            type: String,
            default: 'Pics\'Meals là où la passion culinaire prend vie en une image.'
        },
        headerImage: {
            type: String,
            default: headerPicture
        }
    }
};
</script>
  
  
<style scoped>
.header {
    position: relative;
    height: 500px;
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.overlay h1 {
    margin: 0;
    font-size: 4.5rem;
    margin-bottom: 20px;
}

.overlay p {
    font-size: 1.5rem;
}
</style>
