
export const restaurants = [
        {
            id: 1,
            name: 'Italiano',
            tag: 'Italienne',
            description: 'Restaurant italien avec une variété de pâtes et de pizzas.',
            rating: 4,
            location: '123 Main St, New York, NY',
            address: '25 Av. André Malraux, 37000 Tours',
            city: 'New York',
            image: require('@/assets/restaurant1.png'),
        },
        {
            id: 2,
            name: 'Mexicana',
            tag: 'Mexicaine',
            description: 'Restaurant mexicain avec une variété de tacos et de quesadillas.',
            rating: 3,
            location: '456 Elm St, Los Angeles, CA',
            city: 'Los Angeles',
            image: require('@/assets/restaurant2.jpeg'),
            address: '20 Place du Bois du Roy, 49240 Avrillé'
        },
        {
            id: 3,
            name: 'Sushi Bar',
            tag: 'Japonaise',
            description: 'Restaurant japonais avec une variété de sushis et de sashimis.',
            rating: 5,
            location: '789 Oak St, San Francisco, CA',
            city: 'San Francisco',
            image: require('@/assets/restaurant3.jpeg'),
            address: '22 Bd Pierre Brossolette, 76120 Le Grand-Quevilly'
        },
        {
            id: 4,
            name: 'Spice of India',
            tag: 'Indienne',
            description: 'Restaurant indien avec une variété de currys et de naans.',
            rating: 4,
            location: '101 Pine St, Chicago, IL',
            city: 'Chicago',
            image: require('@/assets/restaurant4.webp'),
            address: 'Bd Henri IV, 34000 Montpellier'
        },
        {
            id: 5,
            name: 'Spice of India',
            tag: 'Indienne',
            description: 'Restaurant indien avec une variété de currys et de naans.',
            rating: 4,
            location: '101 Pine St, Chicago, IL',
            city: 'Chicago',
            image: require('@/assets/restaurant4.webp'),
            address: 'Bd Henri IV, 34000 Montpellier'
        }
    ];

export const menu_restaurant = [
    {
        id: 1,
        plates: [
            {
                id: 1,
                name: 'Pasta Carbonara',
                price: 15,
                description: 'Patte avec pancetta, oeufs, et fromage.',
                composition: 'Pâtes, pancetta, oeufs, fromage',
                image: require('@/assets/plate1.jpg'),
                type: 'Plat',
            },
            {
                id: 2,
                name: 'Margherita Pizza',
                price: 12,
                description: 'Pizza avec sauce tomate, mozzarella, et basilic.',
                composition: 'Pâte, sauce tomate, mozzarella, basilic',
                image: require('@/assets/plate2.jpeg'),
                type: 'Plat',
            },
            {
                id: 3,
                name: 'Tiramisu',
                price: 8,
                description: 'Dessert italien avec café, mascarpone, et cacao.',
                composition: 'Café, mascarpone, cacao',
                image: require('@/assets/plate3.jpeg'),
                type: 'Dessert',
            },
            {
                id:4,
                name: 'Coca-Cola',
                price: 2,
                description: 'Boisson gazeuse.',
                composition: 'Eau gazéifiée, sucre, caféine',
                image: require('@/assets/plate4.jpeg'),
                type: 'Boisson',
            },
        ],
    },
    {
        id: 2,
        plates: [
            {
                id: 1,
                name: 'Taco Al Pastor',
                price: 3,
                description: 'Tortilla de maïs avec porc, ananas, et coriandre.',
                composition: 'Tortillade maïs, porc, ananas, coriandre',
                image: require('@/assets/plate5.jpeg'),
                type: 'Plat',
            },
            {
                id: 2,
                name: 'Quesadilla',
                price: 5,
                description: 'Tortilla de farine avec fromage et viande.',
                composition: 'Tortilla de farine, fromage, viande',
                image: require('@/assets/plate6.jpeg'),
                type: 'Plat',
            },
            {
                id: 3,
                name: 'Churros',
                price: 2,
                description: 'Beignet frit avec sucre et cannelle.',
                composition: 'Farine, sucre, cannelle',
                image: require('@/assets/plate7.jpeg'),
                type: 'Dessert',
            },
            {
                id: 4,
                name: 'Fanta',
                price: 2,
                description: 'Boisson gazeuse.',
                composition: 'Eau gazéifiée, sucre, caféine',
                image: require('@/assets/plate14.jpeg'),
                type: 'Boisson',
            }
        ]
    },
    {
        id: 3,
        plates: [
            {
                id: 1,
                name: 'California Roll',
                price: 10,
                description: 'Sushi aux crabes, avocats, et concombres.',
                composition: 'Riz, crabes, avocats, concombres',
                image: require('@/assets/plate8.webp'),
                type: 'Plat',
            },
            {
                id: 2,
                name: 'Salmon Sashimi',
                price: 12,
                description: 'Sashimi de saumon frais.',
                composition: 'Saumon frais, wasabi, sauce soja',
                image: require('@/assets/plate9.jpeg'),
                type: 'Plat',
            },
            {
                id: 3,
                name: 'Miso Soup',
                price: 5,
                description: 'Soupe de soja fermentée.',
                composition: 'Pâte de soja, algues, tofu',
                image: require('@/assets/plate10.webp'),
                type: 'Entrée',
            }
        ]
    },
    {
        id: 4,
        plates: [
            {
                id: 1,
                name: 'Chicken Tikka Masala',
                price: 15,
                description: 'Curry de poulet avec sauce tomate et crème.',
                composition: 'Poulet, sauce tomate, crème, curry',
                image: require('@/assets/plate11.webp'),
                type: 'Plat',
            },
            {
                id: 2,
                name: 'Naan',
                price: 3,
                description: 'Pain indien cuit au four tandoor.',
                composition: 'Farine, levure, yaourt, beurre',
                image: require('@/assets/plate12.webp'),
                type: 'Plat',
            },
            {
                id: 3,
                name: 'Mango Lassi',
                price: 5,
                description: 'Boisson indienne à base de yaourt et de mangue.',
                composition: 'Yaourt, mangue, sucre',
                image: require('@/assets/plate13.jpeg'),
                type: 'Boisson',
            }]
    }
];