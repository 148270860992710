<template>
<footer class="footer">
    <div class="footer-left">
        <div class="newsletter">
            <input v-model="email" type="email" placeholder="Entre ton email">
            <button @click="subscribe">S'inscrire</button>
        </div>
        <p class="copyright">&copy; 2024 Pics'Meals. All rights reserved.</p>
    </div>
    <div class="footer-center">
        <router-link to="/contact" class="contact">Contactez-nous !</router-link>
    </div>
    <div class="footer-right">
        <div class="social-links">
            <a href="https://www.tiktok.com" target="_blank" class="social-link">
                <img src="@/assets/tiktok.png" alt="TikTok">
            </a>
            <a href="https://www.instagram.com" target="_blank" class="social-link">
                <img src="@/assets/instagram.png" alt="Instagram">
            </a>
        </div>
    </div>
</footer>
</template>

  
  
<script>
export default {
    data() {
        return {
            email: ''
        };
    },
    methods: {
        subscribe() {
            console.log("Subscribing with email:", this.email);
        }
    }
};
</script>
  
  
<style scoped>
.footer {
    background-color: #ccb9a1b5;
    color: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.footer-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-right: 15%;
}

.newsletter {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.copyright {
    margin-top: 20px;
}

.newsletter input,
.newsletter button {
    width: 45%;
    margin: 5px 0;
}

.newsletter input {
    padding: 10px;
    border: none;
    border-radius: 4px 0 0 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 250px;
}

.newsletter button {
    background-color: #ff6347;;
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    padding: 10px 20px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.newsletter button:hover {
    background-color: #ff6347c5;
}

.footer-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.contact {
    background-color: #ff6347;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 1em;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
    margin-right: 5%;
}

.contact:hover {
    background-color: #ff6347c5;
}

.social-links {
    display: flex;
}

.social-link {
    margin-right: 10px;
}

.social-link img {
    width: 30px;
    height: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.social-link img:hover {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
    }

    .footer-left,
    .footer-center,
    .footer-right {
        align-items: center;
        margin-bottom: 5px;
        margin: auto;
    }

    .footer-right {
        flex-direction: row;
    }

    .newsletter {
        width: auto;
    }

    .newsletter input,
    .newsletter button {
        width: 100%;
    }

    .contact {
        margin-bottom: 10px;
    }
}
</style>
