import { createApp } from 'vue'
import PrimeVue from 'primevue/config';
import MainPageComponent from './components/MainPageComponent.vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: MainPageComponent
  },
  {
    path: '/restaurant/:id',
    name: 'RestaurantDetail',
    component: () => import('@/components/RestaurantDetailComponent.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/components/ContactComponent.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

createApp(App).use(router).use(PrimeVue).mount('#app')